import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import { total_updated, shipping_address_updated, shipping_updated, shipping_charge_updated, tax_updated, save_cart_to_storage, cal_total, apply_promocode, clear_error, remove_promocode } from '../redux/cart.redux';
import { TextField, List, ListItem, Button, MenuItem, Grid, CircularProgress } from '@material-ui/core';
import CheckoutStyle from './checkout.module.css';
import Banner from '../components/shoppingBanner';
import Axios from 'axios';
import { API_URL } from '../../Global_Config';
import {countries, country_code2, country_code3} from '../components/countries'
import CheckoutFlowIndicator from '../components/checkoutFlowIndicator'
import MobileOrder from '../components/mobileOrder'
import { navigate } from '@reach/router';
import DesktopOrder from '../components/desktopOrder'

class Checkout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: true, 
            showSuggestions: true,
            name: this.props.cart.shipping_address.name, 
            email: this.props.cart.shipping_address.email,
            organization: this.props.cart.shipping_address.organization,
            addressLine1: this.props.cart.shipping_address.addressLine1,
            addressLine2: this.props.cart.shipping_address.addressLine2,
            city: this.props.cart.shipping_address.city,
            state: this.props.cart.shipping_address.state,
            postalCode: this.props.cart.shipping_address.postalCode,
            country: this.props.cart.shipping_address.country,
            processing: false,
            suggestions: [],
            code: '',
            total_calculated: false,
            stripe: typeof window !==`undefined` ? window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY) : null,
        };
        this.handleResize = this.handleResize.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAutoComplete = this.handleAutoComplete.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleAutoCompleteThrottled = name => event => throttle(this.handleAutoComplete(name, event), 1000);
        this.handleAutoCompleteThrottled = name => event => throttle(this.handleAutoComplete(name, event), 1000);
    }    

    handleResize = e => {
        const windowSize = window.innerWidth;
        this.setState(state => ({...state, isMobile: windowSize < 1000}));
    };

    handleChange = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value, [`${name}Error`]: false }));
    };

    handleAutoComplete = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value }));
        if (event.target.value === '') {
            this.setState(state => ({ ...state, ['showSuggestions']: false}));
        }

        const time = new Date();
        if (time - this.lastSearchTime <= 1200) {
            return;
        }
        this.lastSearchTime = time;

        const here_app_id = process.env.HERE_APP_ID;
        const here_app_code = process.env.HERE_APP_CODE;
        const search = event.target.value.replace(' ', '+');
        
        const url = `https://autocomplete.geocoder.api.here.com/6.2/suggest.json?query=${search}&country=${this.state.country}&app_id=${here_app_id}&app_code=${here_app_code}`;

        Axios.get(url).then(function(response){
                // obj.displayAlert('Thanks for reaching out, we will get back to you shortly!')
                this.setState(state => ({...state, ['suggestions']: response.data.suggestions, ['showSuggestions']: true}));
                
            }.bind(this))
            .catch(function(err) {
                // obj.displayAlert(`Oops, something went wrong... ${err}`)
                console.log("error sending" + err);
            })

    };

    applyPromoCode = () => {
        if (this.state.code) {
            if (!this.state.email) {
                alert('Please enter your email first')
                return;
            }
            if (confirm('Are you sure to apply promo code on this order?'))
            this.props.apply_promocode(this.state.code, this.state.email);
        }
    }

    removePromoCode = async() => {
        if (this.props.cart.promoCode && !this.state.email)
        {
            alert('Please enter your email first')
            return;
        }
        const config = {
            headers:{
                email: this.state.email,
            }
        }
        try {
            await Axios.get(`${API_URL}/promocode/remove/${this.props.cart.promoCode.toUpperCase()}`, config)
            this.props.remove_promocode();
            this.props.cal_total();
        } catch(err) {
            console.log(err)
        }
        
    }
    
    handlePaymentSession = async() => {
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        if (this.props.cart.promoCode) {
            const date = (new Date().toISOString());
            const config = {
                headers:{
                    time: date,
                }
            }
            try {
                await Axios.get(`${API_URL}/promocode/verify/${this.props.cart.promoCode.toUpperCase()}`, config);
            } catch (err) {
                alert('Coupon Expired');
                this.props.remove_promocode();
                this.props.cal_total();
                return;
            }
        }
        const url = "https://api.sonic-instruments.co/api/createCheckoutSession"
        // const url = 'http://localhost:3000/api/createCheckoutSession'
        let items = this.props.cart.items_in_cart.map(item => {
            return {
                price_data: {
                    currency: 'usd',
                    product_data: {
                        name: item.name.toUpperCase(),
                        description: item.description, 
                        metadata : {
                            includesSoftware: item.includesSoftware,
                            licenseName: item.licenseName,
                            licenseType: item.licenseType,
                            expiresAt: item.expiresAt
                        },
                        images: [`https://sonic-instruments.co${item.img}`]
                    },
                    unit_amount: Math.round((item.discountedPrice ? item.discountedPrice : item.price) * 100), 
                },
                quantity: item.quantity
            };
        })

        
        const request = {
            name: this.props.cart.shipping_address.name,
            email: this.props.cart.shipping_address.email,
            address: {
                line1: this.state.addressLine1,
                line2: this.state.addressLine2,
                city: this.state.city,
                state: this.state.state,
                country: country_code2[this.state.country],
                postal_code: this.state.postalCode,
            },
            items_in_cart : items,
        }
        const session = await Axios.post(url, request, config);
        if (this.state.stripe !== null) {
            const { error } = await this.state.stripe.redirectToCheckout({
                'sessionId': session.data.id,
            })
            if (error) {
                console.warn("Error:", error)
            }
        }
    }
    

    handleSubmit() {
        if (!this.props.user)
        {
            navigate('/login', {state: {destination: '/checkout'}});
            return;
        }
        if (this.props.cart.needs_shipping)
        {
            const nameError = !this.state.name;
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            const emailError = !this.state.email || !emailRegex.test(this.state.email);
            const addressError = !this.state.addressLine1;
            const cityError = !this.state.city;
            const stateError = !this.state.state;
            const countryError = !this.state.country;
            this.setState(state=> ({...state, ['nameError']: nameError, ['emailError']: emailError, ['addressLine1Error']: addressError, ['cityError']: cityError, ['stateError']: stateError, ['countryError']: countryError, ['showSuggestions']: false}));
            if (nameError || emailError || cityError || stateError || countryError) return;
            this.setState(state=>({...state, ['processing']: true}));
            const addressObj = {
                'name': this.state.name, 
                'email': this.state.email, 
                'organization': this.state.organization,
                'country': this.state.country,
                'addressLine1': this.state.addressLine1,
                'addressLine2': this.state.addressLine2,
                'city': this.state.city,
                'state': this.state.state,
                'postalCode': this.state.postalCode
            };
            this.props.shipping_address_updated(addressObj);
            if (this.state.country === 'USA') {
                const url = `https://api.sonic-instruments.co/api/checkTax/us/${this.state.postalCode}`
                Axios.get(url).then(function(response) {
                    const tax = response.data.totalRate * this.props.cart.subtotal;
                    this.props.tax_updated(tax);
                    this.props.cal_total();
                    this.props.save_cart_to_storage();
                    setTimeout(function(){
                        this.setState(state=>({...state, ['processing']: false}));
                        navigate('/shipping');
                    }.bind(this), 300);
                }.bind(this)).catch(function(error) {
                    alert(error);
                })
            } else {
                navigate('/shipping');
            }
        }        
        
        else
        {
            const nameError = !this.state.name;
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            const emailError = !this.state.email || !emailRegex.test(this.state.email);
            const addressError = !this.state.addressLine1;
            const cityError = !this.state.city;
            const stateError = !this.state.state;
            const countryError = !this.state.country;
            this.setState(state=> ({...state, ['nameError']: nameError, ['emailError']: emailError, ['addressLine1Error']: addressError, ['cityError']: cityError, ['stateError']: stateError, ['countryError']: countryError, ['showSuggestions']: false}));
            if (nameError || emailError || cityError || stateError || countryError) return;
            const addressObj = {
                'name': this.state.name, 
                'email': this.state.email, 
                'organization': this.state.organization,
                'country': this.state.country,
                'addressLine1': this.state.addressLine1,
                'addressLine2': this.state.addressLine2,
                'city': this.state.city,
                'state': this.state.state,
                'postalCode': this.state.postalCode
            };
            this.props.shipping_address_updated(addressObj);
            this.setState(state=>({...state, ['processing']: true}));
            setTimeout(function() {
                this.handlePaymentSession();
            }.bind(this), 500);
        }
    }

    handleSelectSuggestion = i =>  {
        const data = this.state.suggestions[i];
        const split = data.label.split(', ');
        const address = split[split.length-1];
        const postal = data.address.postalCode;
        const city = data.address.city;
        const stateInfo = data.address.state;
        this.setState(state => ({...state, ['addressLine1']: address, ['city']: city, ['state']: stateInfo, ['postalCode']: postal, ['showSuggestions']: false, ['addressLine1Error']: false, ['cityError']: false, ['stateError']: false}));
    }


    componentDidMount() {
        const windowSize = window.innerWidth;
        this.setState(state => ({...state, isMobile: windowSize < 1000}));
        window.addEventListener("resize", this.handleResize);
        this.lastSearchTime = new Date();
        this.props.tax_updated(0);
        this.props.shipping_charge_updated(0);
        if (!this.props.user)
        {
            navigate('/login', {state: {destination: '/checkout'}});
        }
        else
        {
            if (!this.state.name) this.setState(state => ({...state, name : `${this.props.user?.firstName} ${this.props.user?.lastName}`}));
            if (!this.state.email) this.setState(state => ({...state, email : this.props.user?.email}));
            if (!this.state.addressLine1) this.setState(state => ({...state, addressLine1 : this.props.user?.address?.line1 || ""}));
            if (!this.state.addressLine2) this.setState(state => ({...state, addressLine2 : this.props.user?.address?.line2}));
            if (!this.state.city) this.setState(state => ({...state, city : this.props.user?.address?.city}));
            if (!this.state.state) this.setState(state => ({...state, state : this.props.user?.address?.state}));
            if (!this.state.postalCode) this.setState(state => ({...state, postalCode : this.props.user?.address?.postal_code}));
            if (!this.state.country) this.setState(state => ({...state, country : country_code3[this.props.user?.address?.country]}));
        }
    };


    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this.setState({total_calculated: false});
    };

    componentDidUpdate() {
        if (!this.state.total_calculated) {
            this.props.cal_total();
            this.setState(state=>({...state, total_calculated: true}));
        }
        if (this.props.cart.err) {
            alert(this.props.cart.err);
            this.props.clear_error();
        }
        if (!this.props.user)
        {
            navigate('/login');
        }
    }

    render() {
        const isMobile = this.state.isMobile;
        const suggestionsList = (
            (this.state.suggestions && this.state.suggestions.length) ? 
            this.state.suggestions.map((item, i) => {
                const split = item.label.split(', ').reverse();
                // split.unshift(split.pop());
                const label = ''.concat(split).replace(',', ', ');
                return (
                    <List component='div' aria-label='locationSuggestions' key={`benefit${i}`}>
                        <ListItem button onClick={() => this.handleSelectSuggestion(i)} key={`benefitItem${i}`}>
                            {label}
                        </ListItem>
                    </List>
                )
        })
        : null)
        const country_menu = (
            countries.map((item, i) => {
                return (
                    <MenuItem key={i} value={item.code}>{item.name}</MenuItem>
                )
            })
        )

        const contactInfoSoftware = (
            <div className={CheckoutStyle.contactInfo} style={{width: '100%'}}>
                <h4>Contact Information</h4>
                <div className={CheckoutStyle.infoDiv} style={{marginTop: '1.2rem'}}>
                    <TextField 
                        label="Name"
                        type="name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange('name')}
                        autoComplete="name"
                        margin="dense"
                        variant="outlined"
                        error={this.state.nameError}
                        required={true}
                        fullWidth
                    />
                    <TextField 
                        label="Email"
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange('email')}
                        autoComplete="email"
                        margin="dense"
                        variant="outlined"
                        error={this.state.emailError}
                        required={true}
                        fullWidth
                    />
                    <TextField 
                        label="Address Line 1"
                        type="address-line1"
                        name="address-line1"
                        value={this.state.addressLine1}
                        onChange={this.handleAutoComplete('addressLine1')}
                        autoComplete="address-line1"
                        margin="dense"
                        variant="outlined"
                        error={this.state.addressLine1Error}
                        required={true}
                        fullWidth
                    />
                    {(this.state.suggestions && this.state.suggestions.length && this.state.showSuggestions) ? suggestionsList : null}
                    <TextField 
                        label="Address Line 2"
                        type="address-line2"
                        name="address-line2"
                        value={this.state.addressLine2}
                        onChange={this.handleChange('addressLine2')}
                        autoComplete="address-line2"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    />
                    <Grid container spacing={1} allignItems='center'>
                        <Grid item xs={5}>
                            <TextField 
                                label="City"
                                type="address-level2"
                                name="address-level2"
                                value={this.state.city}
                                onChange={this.handleChange('city')}
                                autoComplete="address-level2"
                                margin="dense"
                                variant="outlined"
                                error={this.state.cityError}
                                required={true}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField 
                                label="State"
                                type="address-level1"
                                name="address-level1"
                                value={this.state.state}
                                onChange={this.handleChange('state')}
                                autoComplete="address-level1"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                error={this.state.stateError}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField 
                                label="Zip code"
                                type="postal-code"
                                name="postal-code"
                                value={this.state.postalCode}
                                onChange={this.handleChange('postalCode')}
                                autoComplete="postal-code"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <TextField 
                        label="Country"
                        select
                        value={this.state.country ? this.state.country : ""}
                        SelectProps = {{
                            renderValue : () => {return (this.state.country)}
                        }}
                        onChange={this.handleChange('country')}
                        error={this.state.countryError}
                        autoComplete="country"
                        margin="dense"
                        variant="outlined"
                        required={true}
                        fullWidth
                    >
                        {country_menu}
                    </TextField>
                </div>
                <h4>Coupon</h4>
                <div style={{height: '1.2rem'}} />
                {this.props.cart.promoCode ? 
                    <React.Fragment>
                        <Button variant='outlined' color='primary' style={{width: 'auto'}} onClick={this.removePromoCode}>Remove promocode</Button>
                    </React.Fragment>
                    : 
                    <div style={{display: 'flex'}}>
                        <TextField variant='outlined' placeholder='Promo code' value={this.state.code} onChange={this.handleChange('code')}/>
                        <Button style={{width: 'auto', marginLeft: '1rem'}} variant='outlined' color='primary' onClick={this.applyPromoCode}>Apply</Button>
                    </div>
                }
                <div style={{height: '32px'}} />
                {this.state.processing ? <CircularProgress /> : null}
                <Grid container justify={this.state.isMobile ? 'center':'space-between'} alignItems='center'>
                    <Grid item xs={this.state.isMobile ? 12 : null}>
                        {this.state.isMobile ? <button className={CheckoutStyle.shippingBtn} onClick={this.handleSubmit}>{this.props.cart.needs_shipping ? 'Shipping' : 'Continue To Payment'}</button>:<button className={CheckoutStyle.backBtn} onClick={()=>navigate('/cart')}>Back to cart</button>}
                    </Grid>
                    <Grid item xs style={this.state.isMobile ? {textAlign:'center'} : {textAlign:'right'}}>
                    {this.state.isMobile ? <button className={CheckoutStyle.backBtn} onClick={()=>navigate('/cart')}>Back to cart</button>:<button className={CheckoutStyle.shippingBtn} onClick={this.handleSubmit}>{this.props.cart.needs_shipping ? 'Shipping' : 'Continue To Payment'}</button>}
                    </Grid>
                </Grid>
            </div>
        )

        const contactInfo = (
            <div className={CheckoutStyle.contactInfo}>
                <h4>Contact Information</h4>
                <div className={CheckoutStyle.infoDiv}>
                    <TextField 
                        label="Email"
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange('email')}
                        autoComplete="email"
                        margin="dense"
                        variant="outlined"
                        error={this.state.emailError}
                        required={true}
                        fullWidth
                    />
                </div>
                <h4>Shipping Address</h4>
                <div className={CheckoutStyle.addressDiv}>
                    <TextField 
                        label="Name"
                        type="name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange('name')}
                        autoComplete="name"
                        margin="dense"
                        variant="outlined"
                        error={this.state.nameError}
                        required={true}
                        fullWidth
                    />
                    <TextField 
                        label="Address Line 1"
                        type="address-line1"
                        name="address-line1"
                        value={this.state.addressLine1}
                        onChange={this.handleAutoComplete('addressLine1')}
                        autoComplete="address-line1"
                        margin="dense"
                        variant="outlined"
                        error={this.state.addressLine1Error}
                        required={true}
                        fullWidth
                    />
                    {(this.state.suggestions && this.state.suggestions.length && this.state.showSuggestions) ? suggestionsList : null}
                    <TextField 
                        label="Address Line 2"
                        type="address-line2"
                        name="address-line2"
                        value={this.state.addressLine2}
                        onChange={this.handleChange('addressLine2')}
                        autoComplete="address-line2"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                    />
                    <Grid container spacing={1} allignItems='center'>
                        <Grid item xs={5}>
                            <TextField 
                                label="City"
                                type="address-level2"
                                name="address-level2"
                                value={this.state.city}
                                onChange={this.handleChange('city')}
                                autoComplete="address-level2"
                                margin="dense"
                                variant="outlined"
                                error={this.state.cityError}
                                required={true}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField 
                                label="State"
                                type="address-level1"
                                name="address-level1"
                                value={this.state.state}
                                onChange={this.handleChange('state')}
                                autoComplete="address-level1"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                error={this.state.stateError}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField 
                                label="Zip code"
                                type="postal-code"
                                name="postal-code"
                                value={this.state.postalCode}
                                onChange={this.handleChange('postalCode')}
                                autoComplete="postal-code"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <TextField 
                        label="Country"
                        select
                        value={this.state.country ? this.state.country : ""}
                        SelectProps = {{
                            renderValue : () => {return (this.state.country)}
                        }}
                        onChange={this.handleChange('country')}
                        error={this.state.countryError}
                        autoComplete="country"
                        margin="dense"
                        variant="outlined"
                        required={true}
                        fullWidth
                    >
                        {country_menu}
                    </TextField>
                </div>
                {this.state.processing ? <CircularProgress /> : null}
                <Grid container justify={this.state.isMobile ? 'center':'space-between'} alignItems='center'>
                    <Grid item xs={this.state.isMobile ? 12 : null}>
                        {this.state.isMobile ? <button className={CheckoutStyle.shippingBtn} onClick={this.handleSubmit}>{this.props.cart.needs_shipping ? 'Shipping' : 'Continue To Payment'}</button>:<button className={CheckoutStyle.backBtn} onClick={()=>navigate('/cart')}>Back to cart</button>}
                    </Grid>
                    <Grid item xs style={this.state.isMobile ? {textAlign:'center'} : {textAlign:'right'}}>
                    {this.state.isMobile ? <button className={CheckoutStyle.backBtn} onClick={()=>navigate('/cart')}>Back to cart</button>:<button className={CheckoutStyle.shippingBtn} onClick={this.handleSubmit}>{this.props.cart.needs_shipping ? 'Shipping' : 'Continue To Payment'}</button>}
                    </Grid>
                </Grid>
            </div>
        )

        



        return (
            <Layout hideSocial={true} hideCart={true}>
                <Banner isMobile={isMobile}/>
                {isMobile ? <MobileOrder /> : null}
                <div className={CheckoutStyle.rightBackground}/>
                <div className={CheckoutStyle.navContainer}>
                    <CheckoutFlowIndicator location='info' />
                </div>
                <div className={CheckoutStyle.container}>
                    {this.props.cart.needs_shipping ? contactInfo : contactInfoSoftware}
                    {this.props.cart.items_in_cart.length > 0 && (!this.state.isMobile || this.props.cart.shipping) !==undefined ? <DesktopOrder /> : null}
                </div>
            </Layout>
        )
    }
}

const mapStatetoProps = (state) => {return {cart: state.cart, isLoggedIn: state.auth.isLoggedIn, user: state.user.data}};
const actionCreators = { total_updated, shipping_address_updated, shipping_updated, shipping_charge_updated, tax_updated, save_cart_to_storage, cal_total, apply_promocode, clear_error, remove_promocode };
export default connect(mapStatetoProps, actionCreators)(Checkout);